<template>
  <div class="flex">
    <div
      @click.stop="logoClicked"
      class="flex-shrink-0 flex items-center cursor-pointer">
      <div>
        <svg class="h-4 flex-shrink-0" x="0px" y="0px" viewBox="0 0 336.4 355.3" style="enable-background:new 0 0 336.4 355.3;" xml:space="preserve">
          <g>
            <path fill="currentColor" d="M336.4,0L94.7,20v228.1v42.2c-9.8-3-21.1-3.9-33.9-2.8c-16.7,1.2-31.2,5.7-42.9,13.1C6,308.4,0,316.9,0,326.2
              s6,16.8,17.9,22.3c11.7,5.7,26.1,7.8,42.9,6.4c16.8-1.2,31.2-5.7,42.9-13.5c5.3-3.2,9.6-8,12.8-13.8c3-5.7,4.6-11.3,5-17.5v-64.5
              l215-17.4L336.4,0L336.4,0z"/>
          </g>
        </svg>
      </div>
      <div v-if="showText" class="ml-2">Sleevenote</div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'Logo',
  computed: {
    ...mapState('album', ['album']),
    ...mapGetters('auth', ['loggedIn']),
    showText(){
      return false// (this.$route.name !== 'album' && this.$route.name !== 'release' && !this.loggedIn) || this.$route.name === 'about'
    },
  },
  methods: {
    logoClicked(){
      this.$router.push({ name: 'home' }).catch(() => {})
    }
  }
}
</script>