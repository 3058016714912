import axios from 'axios'
import { setupCache } from 'axios-cache-adapter'

const cache = setupCache({
  maxAge: 15 * 60 * 1000
})

const api = axios.create({
  adapter: cache.adapter,
  cache: {
    exclude: {
      // Only exclude PUT, PATCH and DELETE methods from cache
      methods: ['put', 'patch', 'delete']
    }
  }
})

export function GetOwnedTokensFromAddress(address){
  return api({
    url: 'https://api.thegraph.com/subgraphs/name/schmidsi/sound-subgraph',
    method: 'post',
    data: {
      query: `
      query GetOwnedTokensFromAddress($userId: String!) {
        account(id: $userId) {
          ownedTokens {
            id
            uri
            artist {
              name
            }
          }
        }
      }`,
      variables: {
        userId: address.toLowerCase()
      }
    }
  })
}

export function GetOwnerFromID(id){
  return api({
    url: 'https://api.thegraph.com/subgraphs/name/schmidsi/sound-subgraph',
    method: 'post',
    data: {
      query: `
      query GetOwnerFromID($id: String!) {
        token(id: $id) {
          owner {
            id
          }
        }
      }`,
      variables: {
        id: id
      }
    }
  })
}

export function GetUserProfile(address){
  return api({
    url: 'https://api.sound.xyz/graphql',
    method: 'post',
    data: {
      query: `
      query getUserProfile($publicAddress: Address!) {
        userByAddress(publicAddress: $publicAddress) {
          ...UserCollectionAndSplitsFragment
          ...ProfileHeaderFragment
          ...ProfileDetailsFragment
          ...ProfileInfoFragment
          id
          publicAddress
          twitterHandle
          avatar {
            id
            url
            __typename
          }
          artist {
            id
            soundHandle
            __typename
          }
          __typename
        }
      }

      fragment UserCollectionAndSplitsFragment on User {
        id
        publicAddress
        twitterHandle
        nftsOwned
        creditAllocations {
          id
          creditSplit {
            id
            mintedReleases {
              ...ReleaseCardFragment
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }

      fragment ReleaseCardFragment on Release {
        ...WaveformInfo
        ...ReleaseQuantities
        id
        title
        titleSlug
        hasRangeBoundSale
        coverImage {
          id
          url
          __typename
        }
        artist {
          id
          name
          soundHandle
          __typename
        }
        createdAt
        editionId
        numSold
        quantity
        mintStartTime
        track {
          id
          duration
          __typename
        }
        publicListeningParty
        finalSaleScheduleEndTime
        __typename
      }

      fragment WaveformInfo on Release {
        ...WaveformCommentsInfo
        id
        track {
          id
          normalizedPeaks
          duration
          __typename
        }
        quantity
        mintStartTime
        __typename
      }

      fragment WaveformCommentsInfo on Release {
        id
        quantityUpperBound
        track {
          id
          duration
          __typename
        }
        topNftsWithComment {
          id
          serialNumber
          songSlot
          avatarUrl
          ownerPublicAddress
          comment {
            id
            message
            __typename
          }
          __typename
        }
        __typename
      }

      fragment ReleaseQuantities on Release {
        id
        quantityLowerBound
        quantityUpperBound
        presaleUpperBound
        numSold
        finalQuantity
        finalSaleScheduleEndTime
        finalSaleScheduleEndTimestamp
        totalRaised
        hasRangeBoundSale
        quantity
        __typename
      }

      fragment ProfileHeaderFragment on User {
        id
        publicAddress
        twitterHandle
        displayName
        avatar {
          id
          url
          __typename
        }
        bannerImage {
          id
          url
          __typename
        }
        artist {
          id
          name
          soundHandle
          openseaCollectionUrl
          bannerImage {
            id
            url
            __typename
          }
          __typename
        }
        __typename
      }

      fragment ProfileDetailsFragment on User {
        id
        createdAt
        publicAddress
        twitterHandle
        instagramHandle
        artist {
          id
          season
          soundHandle
          spotifyUrl
          releasesGenres
          __typename
        }
        __typename
      }

      fragment ProfileInfoFragment on User {
        id
        publicAddress
        twitterHandle
        artistsBacked
        nftsOwned
        artist {
          id
          name
          soundHandle
          numMintedReleases
          numCollectors
          __typename
        }
        __typename
      }`,
      variables: {
        publicAddress: address.toLowerCase()
      }
    }
  })
}

export function AudioFromTrack(id){
  return api({
    url: 'https://api.sound.xyz/graphql',
    method: 'post',
    data: {
      query: `
      query audioFromTrack($trackId: UUID!) {
        audioFromTrack(trackId: $trackId) {
          id
          revealTime
          duration
          releaseId
          audio {
            id
            url
            key
            __typename
          }
          __typename
        }
      }`,
      variables: {
        trackId: id
      }
    }
  })
}

export function GetMintedRelease(handle, slug){
  return api({
    url: 'https://api.sound.xyz/graphql',
    method: 'post',
    data: {
      query: `
      query ReleasePage($soundHandle: String!, $releaseSlug: String!) {
        mintedRelease(soundHandle: $soundHandle, releaseSlug: $releaseSlug) {
          ...ReleasePageContent
          ...ReleaseScrollingBanner
          id
          isFinalSoldOut
          __typename
        }
      }

      fragment ReleasePageContent on Release {
        ...PublicSaleInfo
        ...AnimatedCommentCard
        ...AudienceReleaseInfo
        ...WaveformInfo
        ...BehindTheMusicFragment
        ...GetReleaseDetails
        ...ReleaseQuantities
        ...ReleaseListeningParty
        ...ReleaseCommentType
        id
        hasRangeBoundSale
        title
        behindTheMusic
        finalSaleScheduleEndTime
        publicMintStart
        artist {
          id
          name
          soundHandle
          user {
            id
            twitterHandle
            __typename
          }
          artistContractAddress
          __typename
        }
        rewards {
          id
          title
          __typename
        }
        mintStartTime
        quantity
        numSold
        eggGame {
          id
          winningSerialNum
          nft {
            id
            tokenId
            owner {
              id
              twitterHandle
              publicAddress
              __typename
            }
            __typename
          }
          __typename
        }
        coverImage {
          id
          url
          __typename
        }
        creditSplit {
          id
          ...CreditSplit
          __typename
        }
        track {
          id
          duration
          __typename
        }
        layloUrl
        __typename
      }

      fragment PublicSaleInfo on Release {
        id
        hasRangeBoundSale
        quantity
        numSold
        createdAt
        mintStartTime
        price
        editionId
        finalSaleScheduleEndTime
        publicMintStart
        artist {
          id
          name
          soundHandle
          artistContractAddress
          user {
            id
            publicAddress
            avatar {
              id
              url
              __typename
            }
            __typename
          }
          __typename
        }
        track {
          id
          duration
          __typename
        }
        ...PublicSaleCollectButton
        ...ReleaseTotalRaised
        ...ReleaseQuantities
        ...RangeProgressBarReleaseInfo
        __typename
      }

      fragment PublicSaleCollectButton on Release {
        ...ReleaseAllowedBuyInfo
        ...ReleaseQuantities
        id
        title
        titleSlug
        artist {
          id
          soundHandle
          openseaCollectionUrl
          __typename
        }
        quantity
        numSold
        mintStartTime
        finalSaleScheduleEndTime
        __typename
      }

      fragment ReleaseAllowedBuyInfo on Release {
        ...ReleaseQuantities
        id
        finalSaleScheduleEndTime
        onChainStartDateTime
        publicMintStart
        publicListeningPartyStart
        presaleMintStart
        __typename
      }

      fragment ReleaseQuantities on Release {
        id
        quantityLowerBound
        quantityUpperBound
        presaleUpperBound
        numSold
        finalQuantity
        finalSaleScheduleEndTime
        finalSaleScheduleEndTimestamp
        totalRaised
        hasRangeBoundSale
        quantity
        __typename
      }

      fragment ReleaseTotalRaised on Release {
        id
        totalRaised
        totalRaisedPrimaryUsd
        __typename
      }

      fragment RangeProgressBarReleaseInfo on Release {
        id
        ...ReleaseQuantities
        __typename
      }

      fragment AnimatedCommentCard on Release {
        ...PublicSaleCountdown
        ...ReleaseQuantities
        ...ReleaseCommentType
        id
        coverImage {
          id
          url
          __typename
        }
        quantity
        finalQuantity
        createdAt
        artist {
          id
          name
          user {
            id
            publicAddress
            avatar {
              id
              url
              __typename
            }
            __typename
          }
          __typename
        }
        track {
          id
          __typename
        }
        topNftsWithComment {
          id
          ...NftWithCommentType
          __typename
        }
        __typename
      }

      fragment PublicSaleCountdown on Release {
        id
        hasRangeBoundSale
        coverImage {
          id
          url
          __typename
        }
        mintStartTime
        onChainStartDateTime
        publicListeningPartyStart
        publicMintStart
        presaleMintStart
        presaleListeningPartyStart
        finalSaleScheduleEndTime
        __typename
      }

      fragment ReleaseCommentType on Release {
        id
        artistContractAddress
        track {
          id
          duration
          __typename
        }
        eggGame {
          id
          winningSerialNum
          __typename
        }
        quantityUpperBound
        __typename
      }

      fragment NftWithCommentType on NftWithComment {
        id
        serialNumber
        isPresaleNft
        amountPaidInWei
        purchasedAt
        songSlot
        tokenId
        ownerPublicAddress
        ownerTwitterHandle
        ownerArtistName
        avatarUrl
        comment {
          id
          message
          __typename
        }
        __typename
      }

      fragment AudienceReleaseInfo on Release {
        id
        ...AudienceSeatingReleaseInfo
        ...AudienceTableReleaseInfo
        ...ReleaseQuantities
        ...ReleaseCommentType
        __typename
      }

      fragment AudienceSeatingReleaseInfo on Release {
        id
        eggGame {
          id
          winningSerialNum
          __typename
        }
        ...ReleaseQuantities
        ...BackerPopupReleaseInfo
        __typename
      }

      fragment BackerPopupReleaseInfo on Release {
        id
        ...ReleaseQuantities
        __typename
      }

      fragment AudienceTableReleaseInfo on Release {
        id
        eggGame {
          id
          winningSerialNum
          __typename
        }
        ...ReleaseQuantities
        ...TableBackerReleaseInfo
        __typename
      }

      fragment TableBackerReleaseInfo on Release {
        id
        eggGame {
          id
          winningSerialNum
          __typename
        }
        ...BackerPopupReleaseInfo
        __typename
      }

      fragment WaveformInfo on Release {
        ...WaveformCommentsInfo
        id
        track {
          id
          normalizedPeaks
          duration
          __typename
        }
        quantity
        mintStartTime
        __typename
      }

      fragment WaveformCommentsInfo on Release {
        id
        quantityUpperBound
        track {
          id
          duration
          __typename
        }
        topNftsWithComment {
          id
          serialNumber
          songSlot
          avatarUrl
          ownerPublicAddress
          comment {
            id
            message
            __typename
          }
          __typename
        }
        __typename
      }

      fragment BehindTheMusicFragment on Release {
        id
        behindTheMusic
        title
        artist {
          id
          name
          __typename
        }
        __typename
      }

      fragment GetReleaseDetails on Release {
        ...ReleaseQuantities
        id
        title
        artist {
          id
          name
          soundHandle
          user {
            id
            publicAddress
            avatar {
              id
              url
              __typename
            }
            __typename
          }
          __typename
        }
        coverImage {
          id
          url
          __typename
        }
        mintStartTime
        quantity
        numSold
        eggGame {
          id
          winningSerialNum
          __typename
        }
        track {
          id
          duration
          __typename
        }
        publicListeningPartyStart
        presaleListeningPartyStart
        presaleMintStart
        __typename
      }

      fragment ReleaseListeningParty on Release {
        id
        title
        artist {
          id
          name
          __typename
        }
        track {
          id
          duration
          __typename
        }
        __typename
      }

      fragment CreditSplit on CreditSplit {
        id
        splitAddress
        creditAllocations {
          id
          roles
          percent
          owner {
            id
            artist {
              id
              name
              __typename
            }
            displayName
            publicAddress
            twitterHandle
            __typename
          }
          __typename
        }
        __typename
      }

      fragment ReleaseScrollingBanner on Release {
        id
        artist {
          id
          name
          soundHandle
          isReturningArtist: mintedReleasesPaginated(pagination: {first: 2}) {
            edges {
              node {
                id
                __typename
              }
              __typename
            }
            __typename
          }
          __typename
        }
        titleSlug
        title
        __typename
      }`,
      variables: {
        soundHandle: handle,
        releaseSlug: slug
      }
    }
  })
}

export function GetAudienceNfts(releaseId, after){
  return api({
    url: 'https://api.sound.xyz/graphql',
    method: 'post',
    data: {
      query: `
      query AudienceNfts($releaseId: UUID!, $pagination: NftCursorConnectionArgs!) {
        release(id: $releaseId) {
          id
          nftsPaginated(pagination: $pagination) {
            edges {
              node {
                id
                serialNumber
                ...NftCommentType
                __typename
              }
              __typename
            }
            pageInfo {
              hasNextPage
              endCursor
              __typename
            }
            __typename
          }
          __typename
        }
      }

      fragment NftCommentType on Nft {
        id
        serialNumber
        isPresaleNft
        amountPaidInWei
        updatedAt
        songSlot
        tokenId
        owner {
          id
          publicAddress
          twitterHandle
          artist {
            id
            name
            __typename
          }
          avatar {
            id
            url
            __typename
          }
          __typename
        }
        comment {
          id
          message
          __typename
        }
        __typename
      }`,
      variables: {
        releaseId: releaseId,
        pagination: {
        sort: {
          serialNumber: "ASC"
        },
        first: 50,
        after: after
    }
      }
    }
  })
}

export function GetUserNfts(userId, after){
  return api({
    url: 'https://api.sound.xyz/graphql',
    method: 'post',
    data: {
      query: `
      query UserNfts($userId: UUID!, $pagination: NftCursorConnectionArgs!) {
        user(id: $userId) {
          id
          nftsPaginated(pagination: $pagination) {
            edges {
              node {
                id
                serialNumber
                release {
                  id
                  ...SoundCardFragment
                  __typename
                }
                updatedAtBlockNum
                __typename
              }
              __typename
            }
            pageInfo {
              endCursor
              hasNextPage
              __typename
            }
            __typename
          }
          __typename
        }
      }

      fragment SoundCardFragment on Release {
        ...ReleaseQuantities
        id
        title
        titleSlug
        season
        publicListeningParty
        goldenEggImage {
          id
          url
          __typename
        }
        coverImage {
          id
          url
          __typename
        }
        track {
          id
          __typename
        }
        quantity
        totalRaised
        mintStartTime
        numSold
        eggGame {
          id
          winningSerialNum
          __typename
        }
        artist {
          id
          season
          name
          soundHandle
          openseaCollectionUrl
          user {
            id
            publicAddress
            avatar {
              id
              url
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }

      fragment ReleaseQuantities on Release {
        id
        quantityLowerBound
        quantityUpperBound
        presaleUpperBound
        numSold
        finalQuantity
        finalSaleScheduleEndTime
        finalSaleScheduleEndTimestamp
        totalRaised
        hasRangeBoundSale
        quantity
        __typename
      }`,
      variables: {
        userId: userId,
        pagination: {
          first: 10,
          after: after,
          sort: {
            secondarySaleDate: "DESC"
          }
        }
      }
    }
  })
}