<template>
  <div class="mt-8 space-y-2">
    <div>Owned by
      <div class="ml-2 inline-block w-8" v-if="loading">
        <Loader :style="{ 'color': album.fg_color ? `#${album.fg_color}` : '#000' }" />
      </div>
      <router-link
        v-else-if="handle"
        :to="{ name: 'usercollection', params: { username: handle } }"
        class="border-b-2 border-transparent hover:border-transparent dark:hover:border-transparent cursor-pointer"
        :style="{ 'borderColor': album.fg_color ? `#${album.fg_color}` : '#000' }">@{{ handle }}</router-link>
        <span v-else-if="owner">{{ owner }}</span>
    </div>
    <div>Released via <a
      v-if="album.is_nft"
      :href="album.nft_metadata.url"
      target="_blank"
      class="border-b-2 border-transparent hover:border-transparent dark:hover:border-transparent cursor-pointer"
      :style="{ 'borderColor': album.fg_color ? `#${album.fg_color}` : '#000' }">{{ album.nft_metadata.platform.charAt(0).toUpperCase() + album.nft_metadata.platform.slice(1) }}</a></div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import { GetTokenById } from '@/api/nfts/catalog'
import { GetOwnerFromID, GetMintedRelease, GetAudienceNfts } from '@/api/nfts/sound'

export default {
  name: 'NFTInfo',
  props: {
    album: Object,
  },
  data() {
    return {
      loading: false,
      owner: null,
      handle: null
    }
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  methods: {
    async getOwner(album){
      this.loading = true

      if(this.album.nft_metadata.platform === 'sound'){
        await this.getOwnerSound(album.nft_metadata.platform_id)
      }else if(this.album.nft_metadata.platform === 'catalog'){
        await this.getOwnerCatalog(album.nft_metadata.contract_address, album.nft_metadata.platform_id)
      }

      this.loading = false
    },
    async getOwnerCatalog(address, id){

      let result = await GetTokenById(address, id)

      if(result.data.errors?.length > 0) return null
      if(!result.data.data.Token?.length === 0)return null
      let owner = result.data.data.Token[0].owner
      this.owner = owner
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/listener/${owner}/wallet`)
        this.handle = response.data.username || response.data.wallet
        if(this.user && response.data.wallet === this.user.wallet){
          this.handle = null
          this.owner = 'you'
        }
      } catch(err) {
        return null
      }
    },
    async getOwnerSound(id){
      let owner = ''
      if(this.album.nft_metadata.soundHandle && this.album.nft_metadata.releaseSlug){
        let result = await GetMintedRelease(this.album.nft_metadata.soundHandle, this.album.nft_metadata.releaseSlug)

        let id = result.data?.data?.mintedRelease?.id

        let audience = []
        let hasNextPage = true
        let after = null
        while(hasNextPage){
          let resp = await GetAudienceNfts(id, after)
          audience.push(...resp.data.data.release.nftsPaginated.edges)
          after = resp.data.data.release.nftsPaginated.pageInfo.endCursor
          hasNextPage = resp.data.data.release.nftsPaginated.pageInfo.hasNextPage
        }
        owner = audience.find(o => o.node.serialNumber === this.album.nft_metadata.serialNumber).node.owner.publicAddress
      }else{
        let result = await GetOwnerFromID(id)
        if(!result.data.data.token.owner.id)return null
        owner = result.data.data.token.owner.id
      }

      this.owner = owner
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/listener/${owner}/wallet`)
        this.handle = response.data.username || response.data.wallet
        if(this.user && response.data.wallet === this.user.wallet){
          this.handle = null
          this.owner = 'you'
        }
      } catch(err) {
        return null
      }
    }
  },
  mounted(){
    this.getOwner(this.album)
  }
}
</script>