<template>
  <div class="flex justify-center">

    <div v-if="album" id="covers" class="covers overflow-scroll justify-start" :class="{'mt-10 mb-10': !showFullScreen}" @mousemove="mousemove">

      <div v-if="portrait" class="cover" :style="coverStyle" @click.stop="scrollTo($event.target)">
        <AnimatedImage
          class="select-none"
          :class="{ 'transform -rotate-90': album.rotateImages }"
          :src="album.front_cover"
          @image-loaded="frontCoverLoaded" />
      </div>

      <div
        v-if="album.back_cover"
        class="relative cover"
        :style="coverStyle"
        @click.stop="scrollTo($event.target)">

        <AnimatedImage  class="select-none"
                        :class="{ 'transform -rotate-90': portrait && album.rotateImages }"
                        :src="album.back_cover"
                        :thumb="album.thumbnail" />
        <Canvas class="absolute"
                :album="album"
                ref="canvas"
                :polygons="polygons"
                @track-hover-out="trackHoverOut"
                @track-hover="trackHover"></Canvas>

        <div v-if="showNftIcon"
            class="fixed w-12 pointer-events-none"
            :style="nftIconStyle">
          <img src="@/assets/svg/catalog.svg" />
        </div>
      </div>

      <div v-else class="cover" :style="coverStyle">
        <transition name="fade">
          <AlbumTracklist v-if="showTracklist" :portrait="portrait" :album="album" />
        </transition>
      </div>

      <div v-if="landscape" class="cover" :style="coverStyle" @click.stop="scrollTo($event.target)">
        <AnimatedImage  class="select-none"
                        :src="album.front_cover"
                        @image-loaded="frontCoverLoaded" />
      </div>

      <div v-for="(cover, index) in artwork" :key="index" class="cover" :style="coverStyle" @click.stop="scrollTo($event.target)">
        <AnimatedImage  class="select-none"
                        :class="{ 'transform -rotate-90': portrait && album.rotateImages }"
                        :src="cover"
                        :thumb="cover.thumbnail" />
      </div>

    </div>
  </div>
</template>

<script>
import { services } from '@/services'
//import { mapState } from 'vuex'
import Canvas from '@/components/Canvas'
import { scrollToX, scrollToY } from '@/scrollTo'
import AnimatedImage from '@/components/AnimatedImage'
import AlbumTracklist from '@/components/AlbumTracklist'
import { mapRange } from '@/helpers'
export default {
  name: 'AlbumScroller',
  components: {
    Canvas,
    AnimatedImage,
    AlbumTracklist,
  },
  props: {
    album: Object
  },
  data(){
    return {
      landscape: false,
      portrait: false,
      scrollPos: 0,
      scrollDir: 1,
      showNftIcon: false,
      nftIconPos: {x:0,y:0},
      showTracklist: false
    }
  },
  computed: {
    backCoverStyle(){
      return {
        'background-color': this.album.bg_color ? `#${this.album.bg_color}` : '#fff',
        'color': this.album.fg_color ? `#${this.album.fg_color}` : '#000',
      }
    },
    coverStyle(){
      if(this.portrait){
        return {
          'min-width': '100vw',
          'min-height': '100vw',
        }
      }else{
        return {
          'width': this.showFullScreen ? '100vh' : 'calc(100vh - 5rem)',
          'height': this.showFullScreen ? '100vh' : 'calc(100vh - 5rem)',
        }
      }
    },
    polygons(){
      if(this.portrait && this.album.rotateImages){
        return this.album.rotatedPolygons
      }else{
        return this.album.polygons
      }
    },
    artwork(){
      if(this.portrait && this.album.rotateImages){
        return [this.album.artwork[1],this.album.artwork[0],this.album.artwork[3],this.album.artwork[2]]
      }else{
        return this.album.artwork
      }
    },
    nftIconStyle(){
      return {
        'left': `${this.nftIconPos.x}px`,
        'top': `${this.nftIconPos.y}px`
      }
    },
    showFullScreen(){
      return this.$route.query.fullscreen ? true : false
    }
  },
  methods: {
    frontCoverLoaded(){
      this.showTracklist = true
    },
    playTrack(track, index){
      this.$root.$emit('play-track', track, index)
    },
    mousemove(e){
      this.nftIconPos = {x: e.clientX, y: e.clientY }
    },
    trackHoverOut(){
      this.showNftIcon = false
    },
    trackHover(index){
      if(index >= 0){
        if(this.album.tracks[index].nft){
          //this.nftIconPos = this.polygons[index].points[0]
          this.showNftIcon = true
        }else{
          this.showNftIcon = false
        }
      }
    },
    windowResized(){
      this.setLandscapePortrait()

      //let vh = window.innerHeight * 0.01
      //document.documentElement.style.setProperty('--vh', `${vh}px`)
    },
    setLandscapePortrait(){
      this.portrait = window.innerWidth < window.innerHeight
      this.landscape = window.innerWidth >= window.innerHeight
    },
    wheel(event){
      if(window.innerWidth < window.innerHeight)return
      let covers = document.getElementById('covers')
      if (event.deltaY < -1 || event.deltaY > 1) {
        covers.scrollLeft = covers.scrollLeft + event.deltaY
        this.showNftIcon = false
        event.preventDefault()
      }
    },
    scrollTo(target){
      //target.scrollIntoView({ block: 'center',  behavior: 'smooth' })
    },
    scrollCovers(){
      let covers = document.getElementById('covers')

      let time = (this.album.artwork.length + 2) * 1000
      if(this.portrait){

        let y = covers.scrollHeight - covers.clientHeight
        if(this.scrollDir === 0){
          y = 0
        }
        scrollToY(covers, y, time)

      }else{

        let x = covers.scrollWidth - covers.clientWidth
        if(this.scrollDir === 0){
          x = 0
        }
        scrollToX(covers, x, time)

      }


      setTimeout(function() {
        if(this.scrollDir === 1){
          this.scrollDir = 0
        }else{
          this.scrollDir = 1
        }
        this.scrollCovers()
      }.bind(this), time)
    },
  },
  mounted(){
    window.addEventListener('resize', this.windowResized)
    window.addEventListener('orientationchange', this.windowResized)
    window.addEventListener('wheel', this.wheel, {passive:false})
    this.setLandscapePortrait()

    if(this.$route.name === 'animate'){
      setTimeout(function() {
        this.scrollCovers()
      }.bind(this), 3000)
    }

  },
  beforeDestroy(){
    window.removeEventListener('resize', this.windowResized);
    window.removeEventListener('orientationchange', this.windowResized)
    window.removeEventListener('wheel', this.wheel, {passive:false})
  },
}
</script>

<style scoped>
.modal {
  transition: opacity 0.25s ease;
}

 /* Hide scrollbar for Chrome, Safari and Opera */
.covers::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.covers {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media (orientation: landscape) {
  .covers{
    display: flex;
    flex-direction: row;
    max-width: 100vw;
    /*overflow-x: auto;
    overflow-y: hidden;
    height: auto;
    overscroll-behavior-x: contain;*/
  }
  .cover {
    flex-shrink: 0;
    /* width: calc(100vh - 5rem);
    height: calc(100vh - 5rem); */
  }
  .spacer{
    flex-shrink: 0;
    width: calc(50vw - calc(100vh - 5rem));
    height: calc(100vh - 5rem);
  }
}

@media (orientation: portrait) {
  .covers{
    display: flex;
    flex-direction: column;
  }
  .cover {
    flex-shrink: 0;
    /* min-width: 100vw;
    min-height: 100vw; */
  }
  .spacer{
    flex-shrink: 0;
    width: 100vw;/*calc(100vw - 5rem);*/
    height: calc(50vh - 100vw);/*calc(50vh - calc(100vw - 5rem));*/
    min-height: 7rem;
    background-color:black;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>